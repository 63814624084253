import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  distinctUntilChanged,
  filter,
  retry,
  share,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';

const API_ENDPOINT = `${environment.BACKEND_URL}/data/mandant`;

@Injectable({
  providedIn: 'root',
})
export class MandantenService {
  private _currentMandantId$: BehaviorSubject<number> = new BehaviorSubject(0);

  private _StoreState: BehaviorSubject<StoreState> = new BehaviorSubject(
    'DISCONNECTED' as StoreState
  );
  private _Store: ReplaySubject<Array<Mandant>> = new ReplaySubject(1);
  private _mandantSettings$: Observable<MandantSettings> | null = null;

  constructor(public httpClient: HttpClient) {
    this._StoreState
      .asObservable()
      .pipe(
        filter((state) => state === 'FETCH'),
        tap(() => this._StoreState.next('LOADING')),
        switchMap(() => {
          return this.httpClient
            .get<Array<Mandant>>(`${API_ENDPOINT}/`)
            .pipe(retry({ count: 4, delay: 1000 }));
        }),
        tap((result) => {
          this._Store.next(result);
          this._StoreState.next('CONNECTED');
        })
      )
      .subscribe();
  }

  get Store$(): Observable<Array<Mandant>> {
    return this._StoreState.asObservable().pipe(
      switchMap((state) => {
        switch (state) {
          case 'DISCONNECTED':
          case 'RELOAD':
            this._StoreState.next('FETCH' as StoreState);
        }

        return this._Store.asObservable();
      })
    );
  }

  get currentMandantId$(): Observable<number> {
    return this._currentMandantId$.asObservable().pipe(filter((id) => id > 0));
  }

  set currentMandantId(id: number) {
    this._currentMandantId$.next(id);
  }

  get mandantSettings$(): Observable<MandantSettings> {
    if (this._mandantSettings$ === null) {
      this._mandantSettings$ = this.currentMandantId$.pipe(
        distinctUntilChanged(),
        filter((id) => id > 0),
        switchMap((id) => {
          return this.httpClient.get<MandantSettings>(
            `${API_ENDPOINT}/${id}/settings`
          );
        }),
        shareReplay(1)
      );
    }
    return this._mandantSettings$;
  }
}
export type Mandant = {
  _id: number;
  _gid: string;
  code: string;
  beschreibung: string;
};

export type MandantSettings = {
  id: number;

  _created_at?: Date;

  _updated_at?: Date;

  _gid: string;

  _version?: number;

  _created_by?: number;

  _updated_by?: number;

  vatid: string;

  invoice_email: string;

  company_name: string;

  company_strasse: string;
  company_plz: string;
  company_ort: string;

  company_telefon: string;
  company_hrb: string;
  company_gln: string;

  company_reg: string;
  company_emailfooter: string;

  default_country_id: number;

  la_bank_iban: string;
  la_bank_bic: string;
  la_bank_name: string;
  la_bank_gid: string;
  la_bank_sepa_batch: boolean;

  ub_bank_iban: string;
  ub_bank_bic: string;
  ub_bank_name: string;

  mandant_id: number;

  kostenstelle?: number;

  company_taxnumber?: string;

  autodebitor?: boolean;
};

export type StoreState =
  | 'LOADING'
  | 'RELOAD'
  | 'CONNECTED'
  | 'DISCONNECTED'
  | 'FETCH';
